<template>
  <!-- begin::kb-main -->
  <main class="kb-main">
    <div class="home-main-component">
      <div class="home-contents">
        <!-- begin::좌측 메인 ASIDE -->
        <aside class="aside">
          <!-- begin::마이페이지 -->
          <section class="my section">
            <MySection my-class-name="my-header" :is-kb-bank="isKbBank"/>
            <MyImage/>
          </section>
          <!-- end::마이페이지 -->

          <!-- begin::오늘 -->
          <section class="today section">
            <div class="section-title-area">
              <div class="titles">
                <h2>오늘</h2>
              </div>
              <div class="additional">
                <a href="javascript:" class="text-muted">{{ getTodayDate('년월일') }}</a>
              </div>
            </div>
            <TodayList :is-kb-bank="isKbBank"/>
          </section>
          <!-- end::오늘 -->

          <!-- begin::오늘 -->
          <section class="hottip section">
            <div class="section-title-area">
              <div class="titles">
                <h2>핫팁(Hot Tip)</h2>
              </div>
              <div class="additional">
                <router-link :to="{name: 'HotTipMain'}" class="text-gold">Hot Tip 바로가기</router-link>
              </div>
            </div>
            <template v-if="hottipLoading">
              <LoadingDiv v-model="hottipLoading"/>
            </template>
            <template v-else>
              <div class="board-preview-list">
              <ul class="board-preview-table">
                <li v-for="(item,idx) in hotTipMyPreviewList" :key="idx" class="kb-table-row" :class="{'opacity-50': item.isRead === 'Y'}">
<!--                  <div class="kb-table-cell category-area">-->
<!--                    <span class="text-gold">{{item.cateNm}}</span>-->
<!--                  </div>-->
                  <div class="kb-table-cell title-area">
                    <div class="d-flex align-items-center">
                      <span class="text kb-mouse-cursor" :title="item.postTitle" @click="moveToBoardDtl(item)">{{item.postTitle}}</span>
                      <i v-if="item.isNew === 'Y'" class="icon-new"></i>
                    </div>
                  </div>
                </li>
              </ul>
              </div>
            </template>
          </section>
          <!-- end::오늘 -->

          <!-- begin::최근배지 -->
          <section v-if="isKbBank" class="badges section">
            <div class="section-title-area">
              <div class="titles">
                <h2>최근 배지</h2>
              </div>
              <div class="additional">
                <router-link :to="{name: 'BadgeBox'}" class="text-gold">배지 보관함</router-link>
              </div>
            </div>
            <ul class="badge-items">
              <RecentBadges
                  v-if="!isBadgeLoading"
                  :badgeTyCdDcds="badges"
              />
            </ul>
          </section>
          <!-- end::최근배지 -->
        </aside>
        <!-- end :: 좌측 메인 ASIDE -->

        <!-- begin :: 우측 메인 섹션 -->
        <div class="main-articles">
          <swiper
              class="home-banner"
              :modules="modules"
              :speed="750"
              effect="fade"
              :autoplay="{
                  delay: 5000,
                  disableOnInteraction: false,
                  stopOnLast: true,
              }"
              @slideChange="slideChange"
              @swiper="setControlledSwiper"
          >
            <swiper-slide v-if="isBannerLoading && baners.length === 0" class="home-banner-item">
              <h1></h1>
              <video :src="bannerVideo" autoplay="" muted="muted" loop=""></video>
            </swiper-slide>
            <swiper-slide v-for="(item, idx) in baners" :key="idx" class="home-banner-item">
              <a href="javascript:" class="banner-link" @click="moveLink(item.banerUrl)">
                <template v-if="item.type === 'video'">
                  <h1 v-if="item.banerCn">{{ item.banerCn }}</h1>
                  <video :src="item.thumb" autoplay="true" muted="muted" loop=""></video>
                </template>
                <img v-else :src="item.thumb" alt="">
              </a>
            </swiper-slide>
            <div v-if="baners.length > 1" class="arrow-nav">
              <button class="icon-arrow-prev kb-btn-baner-prev" :class="{'is-active' : isEnd}"
                      @click="moveSlide(false)"></button>
              <button class="icon-arrow-next kb-btn-baner-next" :class="{'is-active' : isBeginning}"
                      @click="moveSlide(true)"></button>
            </div>
          </swiper>

          <div class="section-group">

            <section v-if="isKbBank" class="hrdcloud section">

              <div class="section-title-area">
                <div class="titles fl">
                  <h2 class="title">HRD 클라우드</h2>
                  <span class="bar"></span>
                  <ul class="title-buttons">
                    <li v-for="(item, idx) in mainList"
                        :key="idx"
                        :class="{ 'is-active' : hrdCloudFilter === item.name }"
                        @click="hrdCloudFilter = item.name">
                      <a href="javascript:">{{ item.title }}</a>
                    </li>
                  </ul>
                </div>
                <div class="additional">
                  <router-link :to="{name: 'HrdCloud'}" class="text-gold">HRD 클라우드 바로가기</router-link>
                </div>
              </div>
              <div class="hrdcloud-wrapper">
                <template v-if="hrdLoading">
                  <LoadingDiv v-model="hrdLoading"/>
                </template>
                <template v-else>
                  <!--                <swiper-->
                  <!--                    v-if="items"-->
                  <!--                    class="hrdcloud-list"-->
                  <!--                    :modules="modules"-->
                  <!--                    slides-per-view="auto"-->
                  <!--                    effect="fade"-->
                  <!--                    :navigation="{-->
                  <!--                      nextEl: '.swiper-btn-prev',-->
                  <!--                      prevEl: '.swiper-btn-next'-->
                  <!--                    }"-->
                  <!--                >-->
                  <HrdCloudSwiper :items="items" :show-put="true" :session="session" />
                  <!--                  <swiper-slide v-for="(item, idx) in items" :key="idx" class="hrdcloud-item">-->
                  <!--                    <template v-if="item">-->
                  <!--                      <HrdCloudContent-->
                  <!--                          :item="item"-->
                  <!--                          :idx="idx"-->
                  <!--                          :session="session"-->
                  <!--                          :is-put-action="false"-->
                  <!--                      />-->
                  <!--                    </template>-->
                  <!--                  </swiper-slide>-->
                  <!--                </swiper>-->
                  <!--                <div class="arrow-nav">-->
                  <!--                  <button class="kb-btn-badge-prev icon-arrow-prev is-active"></button>-->
                  <!--                  <button class="kb-btn-badge-next icon-arrow-next is-active"></button>-->
                  <!--                </div>-->
                </template>
              </div>
            </section>

            <section v-if="isKbBank" class="section popular-lec">
              <div class="section-title-area">
                <div class="titles">
                  <h2>지금 주목할 연수</h2>
                </div>
                <div class="additional">
                  <a href="javascript:" @click="moveTrain('trainMain')" class="text-gold">연수신청 바로가기</a>
                </div>
              </div>
              <div class="lec-wrapper">
                <ul class="lec-list">
                  <template v-if="courseItems && courseItems.length > 0">
                    <li v-for="(item, idx) in courseItems" :key="idx" class="lec-item">
                      <div class="lec-inner">
                        <a href="javascript:" @click="moveTrain('trainView', item)" class="lec-link">
                          <div class="lec-img">
                            <img v-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                            <CourseThumb v-else :num="item.crseMstSn"/>
                          </div>
                          <div class="lec-content">
                            <p class="title">{{ item.crseDists[0].crseNm }}</p>
                            <p class="text">
                              {{ timestampToDateFormat(item.crseDists[0].bgngDt, 'yyyy.MM.dd') }}-{{ timestampToDateFormat(item.crseDists[0].endDt, 'yyyy.MM.dd') }}</p>
                          </div>
                          <i class="main-arrow"></i>
                        </a>
                      </div>
                    </li>
                  </template>
                  <li v-else class="lec-item">
                    <strong class="text">신청 가능한 연수가 없습니다.</strong>
                  </li>
                </ul>
              </div>
            </section>
          </div>

          <!-- begin::HRD 클라우드-->
          <section v-if="isKbBank" class="shorts section">

              <div class="section-title-area">
                <div class="titles fl">
                  <h2 class="title">숏클</h2>
                </div>
                <div class="additional">
                  <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1:'-99'}}" class="text-gold">숏클 바로가기</router-link>
                </div>
              </div>
              <div class="hrdcloud-wrapper">
                <template v-if="hrdLoading">
                  <LoadingDiv v-model="hrdLoading"/>
                </template>
                <template v-else>
                  <HrdCloudSwiper :items="shortsItems" :show-put="true" :session="session" :is-shorts="true"/>
                </template>
              </div>

          </section>
          <!-- end::HRD 클라우드-->

          <!-- begin::학습노트-->
          <section v-if="isKbBank" class="note section">
            <div class="section-title-area" style="display: flex; justify-content: space-between">
              <div class="titles fl">
                <h2 class="title">SSL Studio</h2>
                <span class="bar"></span>
                <ul class="title-buttons">
                  <li v-for="(item, idx) in sslList"
                      :key="idx"
                      :class="{ 'is-active' : sslCardFilter === item.name }"
                      @click="sslCardFilter = item.name">
                    <a href="javascript:">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div>
                <div class="additional">
                  <a href="javascript:" class="text-gold" @click="moveSSL">SSL 바로가기</a>
                  <!--                                <a href="javascript:" class="text-gold" @click="showMessage('2025년 SSL 준비중입니다.');">SSL 바로가기</a>-->
                </div>
              </div>
            </div>
            <div class="board-list-wrapper" style="padding:0 20px">
              <!-- board-list -->
              <template v-if="sslLoading">
                <LoadingDiv v-model="sslLoading" />
              </template>
              <template v-else>
                <!-- board-list -->
                <template v-if="sslitems.length > 0">
                  <SSLNoteCard :slides-per-view="4"
                               :sslItems="sslitems"/>
                  <!--:swiper-class="`excellent-board-card-type`"-->
                </template>
                <template v-else>
                  <div class="search-container">
                    <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                        src="../../assets/lxp/images/common/img_empty.png" alt="">
                      <p v-if="sslCardFilter === 'recommend'" class="text">추천 SSL이 없습니다.</p>
                      <p v-if="sslCardFilter === 'excellent'" class="text">인기 SSL이 없습니다.</p>
                      <p v-else class="text">구독 SSL이 없습니다.</p>
                    </div>
                  </div>
                </template>
              </template>
              <!-- //board-list -->
            </div>
          </section>

<!--          <section v-if="isKbBank" class="note section mt-4">-->
<!--            <div class="section-title-area" style="justify-content: flex-start;">-->
<!--              <div class="titles fl">-->
<!--                <h2 class="title">구독채널 신규</h2>-->
<!--              </div>-->
<!--              <template  v-if="subscribeCnt == 0">-->
<!--                <span class="bar"></span>-->
<!--                <span class="text-muted mt-1" style="margin-left: 10px">구독중인 채널이 없어 인기 채널의 신규 콘텐츠를 제공합니다. 관심 채널을 구독해보세요!</span>-->
<!--              </template>-->
<!--&lt;!&ndash;              <div class="additional">&ndash;&gt;-->
<!--&lt;!&ndash;                <a href="javascript:" class="text-gold" @click="moveSSL">SSL 바로가기</a>&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash;                                <a href="javascript:" class="text-gold" @click="showMessage('2025년 SSL 준비중입니다.');">SSL 바로가기</a>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="board-list-wrapper" style="padding:0 20px">-->
<!--              &lt;!&ndash; board-list &ndash;&gt;-->
<!--              <template v-if="sslSubscLoading">-->
<!--                <LoadingDiv v-model="sslSubscLoading" />-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                &lt;!&ndash; board-list &ndash;&gt;-->
<!--                <template v-if="subscribeItems.length > 0">-->
<!--                  <SSLNoteCard :slides-per-view="4"-->
<!--                               :sslItems="subscribeItems"/>-->
<!--                  &lt;!&ndash;:swiper-class="`excellent-board-card-type`"&ndash;&gt;-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                  <div class="search-container">-->
<!--                    <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img-->
<!--                        src="../../assets/lxp/images/common/img_empty.png" alt="">-->
<!--                      <p class="text">구독직원의 SSL 학습노트가 없습니다.</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </template>-->
<!--              &lt;!&ndash; //board-list &ndash;&gt;-->
<!--            </div>-->
<!--          </section>-->
          <!-- end::학습노트-->
        </div>
        <!-- end :: 우측 메인 섹션 -->
      </div>
      <div v-if="isKbBank" class="end-banner">
        <div class="square-list-wrap">
          <SquareList/>
        </div>
      </div>

    </div>
    <BanerPopupModal
        v-if="popups.length > 0 && !expirationPopup"
        :items="popups"
    />
  </main>
  <!-- end::kb-main -->
</template>
<style scoped>
@import url(../../assets/lxp/css/ssl.common.css);
@import url(../../assets/lxp/css/ssl.board.css);
</style>
<script>
import {computed, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import MySection from '@/components/main/MySection';
import MyImage from '@/components/main/MyImage';
import SquareList from '@/components/main/SquareList';
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import LoadingDiv from '@/components/common/LoadingDiv';
import {ACT_GET_LEARN_HISTORY_LIST} from '@/store/modules/learn/learn';
import {
  getCheckItems,
  getItems,
  getTodayDate,
  stringCheck,
  timestampToDateFormat,
  lengthCheck,
  getItem
} from '@/assets/js/util';
import {getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_ALL_BADGE_LIST} from '@/store/modules/badge/badge';
import {ACT_GET_CRSE_APPLY_LIST} from '@/store/modules/course/course';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import CourseThumb from '@/components/apply/CourseThumb';
import TodayList from '@/components/main/TodayList';
import {
  banerDivCdDcds,
  banerExpsrLocCdDcds,
  getBanerList,
  getJuniorBaner,
} from '@/assets/js/modules/baner/common-baner';
import {getCrseSnToDistCrseCd, openPopUp, ssoKB} from '@/assets/js/modules/ssl/ssl-common';
import {ACT_SSL_SSO_AUTH} from '@/store/modules/auth/auth';
import SSLNoteCard from '@/components/main/SSLNoteCard';
import BanerPopupModal from '@/components/main/BanerPopupModal';
import RecentBadges from '@/components/main/RecentBadges';
import {useAlert} from '@/assets/js/modules/common/alert';
import router from '@/router';
import {goLink, moveSSL} from '@/assets/js/modules/common/common';
import {
  ACT_GET_HRDCLOUD_LATEST_LIST,
  ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  ACT_GET_HRDCLOUD_RECOMMEND_LIST,
} from '@/store/modules/prep/prep';
import {
  ACT_GET_BEST_SUBSCRIBES_NOTE_LIST,
  ACT_GET_SOC_CNT,
  ACT_GET_SOC_EXCELLENT_BOARD_LIST,
  ACT_GET_SOC_RECPOP_BOARD_LIST, ACT_GET_SOC_SUBSCRIBE_BOARD_LIST,
  // ACT_GET_SOC_SUBSCRIBE_BOARD_LIST
} from "@/store/modules/ssl/ssl-index";
import {getPostList} from "@/assets/js/modules/board/board-common";
import {ACT_GET_BOARD_CATE_LIST, ACT_GET_BOARD_MST} from "@/store/modules/board/board-mst";
import HrdCloudSwiper from "@/components/hrdcloud/HrdCloudSwiper.vue";

export default {
  name: 'Main',
  components: {
    HrdCloudSwiper,
    RecentBadges,
    BanerPopupModal,
    SSLNoteCard,
    TodayList,
    CourseThumb, SquareList, MyImage, MySection, Swiper, SwiperSlide, LoadingDiv
  },
  setup() {
    const store = useStore();
    const {showMessage} = useAlert();
    const bannerVideo = ref(`${process.env.VUE_APP_CLOUDFRONT_URL}/contents/hmpg/baner/thumb/2022_baner.mp4`);
    const todayBanner = require('../../assets/lxp/videos/todday_banner.mp4');
    const hrdCloudFilter = ref('latest');
    const shortsFilter = ref('shorts');
    const sslCardFilter = ref('recommend');
    const myStorage = ref('subscribed');
    const session = computed(() => store.state.auth.session);
    const underConstruction = () => {
      return alert('준비중입니다.');
    };
    const isKbBank = computed(() => session.value.susdyCdDcd && (session.value.susdyCdDcd === '2109001') && session.value.jbpsCd !=='A99');
    const isLoading = ref(false);
    const sslLoading = ref(false);
    const hottipLoading = ref(false);
    const isBannerLoading = ref(false);
    const isBadgeLoading = ref(true);
    const learnHistoryItems = ref([]);
    const currentTs = ref(new Date().getTime());
    const proceeding = ref(0);
    const scheduled = ref(0);
    const completed = ref(0);
    const yearStr = ref(new Date().getFullYear());
    const badges = ref([]);
    const courseItems = ref([]);
    const baners = ref([]);
    const isBeginning = ref(true);
    const isEnd = ref(false);
    let nowDate = new Date();
    nowDate = nowDate.setTime(currentTs.value);
    // 현재 시각과 스토리지에 저장된 시각을 각각 비교하여
    // 시간이 남아 있으면 true, 아니면 false 리턴
    const expirationPopup = computed(() => parseInt(localStorage.getItem('kb-popup-main')) > nowDate);

    const mainList = ref([
      {name: 'latest', title: '신규', params: {category1: 2}, isLoading: true, items: []},
      {name: 'latestPopularDuty', title: '인기', params: {category1: 1}, isLoading: true, items: []},
      {name: 'recommended', title: '추천', params: {category1: 1}, isLoading: true, items: []},
    ]);

    const sslList = ref([
      { name: 'recommend', title: '추천', isLoading: true, items:[] },
      { name: 'excellent', title: '인기', isLoading: true, items:[] },
      { name: 'subscribed', title: '구독', isLoading: true, items:[] },
    ]);

    const paging = reactive({pageNo: 1, pageSize: 6});

    // HRD클라우드 신규,인기,추천 리스트
    const hrdLoading = ref(true);
    const items = ref([]);
    const sslitems = ref([]);
    const latestItems = ref([]);
    const popularDutyItems = ref([]);
    const recommendedItems = ref([]);
    const shortsItems = ref([]);
    const excellentItems = ref([]);
    const sslRecommendItems = ref([]);
    const subscribeItems = ref([]);
    //hottip 정보
    const hotTipMyPreviewList = ref([]);

    // 쓸이 종료된 경우 구독 학습노트 숨김 처리
    store.dispatch(`ssl/${ACT_GET_SOC_CNT}`).then(res => {
      if(res && res.cnt === 0) {
        sslList.value[1].title = '';
      }
    });


    const getHotTipList = async (boardId)=>{
      hottipLoading.value = true;
      let boardMst = null;
      let boardCateList = [{cateNm:'전체', boardCateSn:''}];
      await store.dispatch(`boardmst/${ACT_GET_BOARD_MST}`, boardId).then(async (res) => {
         boardMst = getItem(res);
        await store.dispatch(`boardmst/${ACT_GET_BOARD_CATE_LIST}`, boardMst.boardMstSn).then((res) => {
           boardCateList = boardCateList.concat(getItems(res));
        }).finally(async () => {
          await getPostList({boardMstSn:boardMst.boardMstSn, topFixYn:'N', pageNo:1, pageSize:7}, hotTipMyPreviewList, null, boardCateList);
        })
      });

      hottipLoading.value = false;

    }

    const getHrdCloudList = async (hrdCloudFilter) => {
      hrdLoading.value = true;
      if (hrdCloudFilter.value === 'latest') {
        if (!stringCheck(latestItems.value)) {
          const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {category1: 2, ...paging});
          latestItems.value = getItems(res);
        }
        items.value = latestItems.value;
      } else if (hrdCloudFilter.value === 'latestPopularDuty') {
        if (!stringCheck(popularDutyItems.value)) {
          const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: '', ...paging});
          popularDutyItems.value = getItems(res);
        }
        items.value = popularDutyItems.value;
      } else if (hrdCloudFilter.value === 'recommended') {
        if (!stringCheck(recommendedItems.value)) {
          const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`, paging);
          recommendedItems.value = getItems(res);
        }
        items.value = recommendedItems.value;
      } else if (hrdCloudFilter.value === 'shorts') {
        if (!stringCheck(shortsItems.value)) {
          // const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`, paging);
          let shorts = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {pageNo: 1, pageSize: 10, picRate: '9.16'});
          console.log("shortsItems = ", shorts);
          shortsItems.value = getItems(shorts);
          console.log("shortsItems = ", shorts);
        }
        // items.value = shortsItems.value;
      }
      hrdLoading.value = false;
    };

    // const sslSubscLoading = ref(false);
    const subscribeCnt = ref(0);

    const getsslCardList = async (sslCardFilter) => {
      sslLoading.value = true;
      if (sslCardFilter.value === 'excellent') {
        if (!stringCheck(excellentItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
            if(lengthCheck(res)) {
              // console.log("res.value");
              // console.log(getItems(res));
              excellentItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
              }));
              // console.log("sslitems.value");
              // console.log(sslitems.value);
            }
          });
        }
        sslitems.value = excellentItems.value;
        sslLoading.value = false;
      } else if (sslCardFilter.value === 'recommend') {
        sslRecommendItems.value = [];
        await store.dispatch(`ssl/${ACT_GET_SOC_RECPOP_BOARD_LIST}`, {seqNm: '0'}).then(res => {
          if (lengthCheck(res)) {
            // console.log("res.value");
            // console.log(getItems(res));
            sslRecommendItems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
            }));
            // console.log("sslitems.value");
            // console.log(sslitems.value);
          }
        });
        // if (sslRecommendItems.value == null || sslRecommendItems.value.length == 0) {
        //   sslCardFilter.value = 'excellent';
        // } else {
        //   sslitems.value = sslRecommendItems.value;
        // }
        sslitems.value = sslRecommendItems.value;
        sslLoading.value = false;
      }else if(sslCardFilter.value == 'subscribed'){

        sslLoading.value = true;
        subscribeItems.value = [];
        store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
          if(lengthCheck(res)) {
            // console.log("res.value");
            // console.log(getItems(res));
            subscribeItems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
            }));
            // console.log("subscribe.value");
            // console.log(sslitems.value);
            subscribeCnt.value = subscribeItems.value.length;
            sslitems.value = subscribeItems.value;
            sslLoading.value = false;
          }else{
            subscribeCnt.value = 0;
            getBestSubscribesNoteList();
          }
        });
      }
    };
    getsslCardList(sslCardFilter);
    // const getsslSubscribeCardList =()=>{
    //
    //   sslSubscLoading.value = true;
    //   if (!stringCheck(subscribeItems.value)) {
    //     store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
    //       if(lengthCheck(res)) {
    //         // console.log("res.value");
    //         // console.log(getItems(res));
    //         subscribeItems.value = getItems(res).map(x => ({
    //           ...x,
    //           date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
    //           day: timestampToDateFormat(x.pblcnDt, 'dd'),
    //           distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
    //         }));
    //         // console.log("subscribe.value");
    //         // console.log(sslitems.value);
    //         subscribeCnt.value = subscribeItems.value.length;
    //       }else{
    //         subscribeCnt.value = 0;
    //         getBestSubscribesNoteList();
    //       }
    //     });
    //   }
    //   sslSubscLoading.value = false;
    // }
    // getsslSubscribeCardList();

    const getBestSubscribesNoteList=()=>{
      sslLoading.value = true;
      store.dispatch(`ssl/${ACT_GET_BEST_SUBSCRIBES_NOTE_LIST}`).then(res => {
        if(lengthCheck(res)) {
          // console.log("res.value");
          // console.log(getItems(res));
          subscribeItems.value = getItems(res).map(x => ({
            ...x,
            date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
            day: timestampToDateFormat(x.pblcnDt, 'dd'),
            distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
          }));
          // console.log("subscribe.value");
          // console.log(sslitems.value);
        }
      });
      sslitems.value = subscribeItems.value;
      sslLoading.value = false;
    }

    watch(() => hrdCloudFilter.value, () => {
      getHrdCloudList(hrdCloudFilter);
    });

    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter);
    });

    const getLearnHistoryList = () => {
      store.dispatch(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}).then(res => {
        learnHistoryItems.value = getCheckItems(res)
            .filter(x => x.logCrseFg === 'Y'|| x.splCrseTyCdDcd ==='2066024') // 의무교육 포함
            .filter(x => {
              if (!['2066006', '2066008', '2066009', '2066017', '2066022','2066031'].includes(x.splCrseTyCdDcd) && x.crseMstSn !== 15224) return true; // 설문, 클라우드, 등 제외
            });
        proceeding.value = learnHistoryItems.value.filter(x => (x.endDt >= currentTs.value && x.bgngDt <= currentTs.value)).length; // 진행중
        scheduled.value = learnHistoryItems.value.filter(x => x.bgngDt > currentTs.value).length; // 예정된
        // completed.value = learnHistoryItems.value.filter(x => timestampToDateFormat(x.endDt, 'yyyy') == getTodayDate('yyyy') && x.endDt < currentTs.value  ).length; // 완료(미수료, 처리중 포함)
        // console.log(learnHistoryItems.value);
        // console.log(learnHistoryItems.value.filter(x => timestampToDateFormat(x.bgngDt, 'yyyy') == getTodayDate('yyyy')   && x.endDt < currentTs.value));
        // console.log("learnHistoryItems.value");
        completed.value = learnHistoryItems.value.filter(x => timestampToDateFormat(x.bgngDt, 'yyyy') == getTodayDate('yyyy')   && x.endDt < currentTs.value).length; // 완료(미수료, 처리중 포함)
      }).catch(e => {
        console.error(e);
      });
    }

    const getBadgeList = () => {
      isBadgeLoading.value = true;
      getListFunc(`badge/${ACT_GET_ALL_BADGE_LIST}`, {
        crsePrfx1: yearStr.value,
        sortYn: 'Y',
        ...paging
      }, badges, paging, () => {
        isLoading.value = false;
        isBadgeLoading.value = false;
      }, () => {
        isLoading.value = false;
        isBadgeLoading.value = false;
      });
    };

    const excludeCrse = [100331, 100332];
    // 지금 주목할 연수
    getListFunc(`course/${ACT_GET_CRSE_APPLY_LIST}`, {
      yearRangeYn: 'Y',
      pageNo: 1,
      pageSize: 5,
      getType:'main-recommand'
    }, courseItems, null, () => {
      courseItems.value = courseItems.value.map(x => ({
        ...x,
        crseDists: x.crseDists.filter(y => y.aplyBgngDt <= currentTs.value && y.aplyEndDt > currentTs.value)
      }))
      let isDigiFirst = courseItems.value.filter(x => excludeCrse.includes(x.crseMstSn));
      if (isDigiFirst.length > 0) {
        courseItems.value = courseItems.value.filter(x => !excludeCrse.includes(x.crseMstSn));
        if (courseItems.value.length > 3) {
          courseItems.value = courseItems.value.slice(0, 3);
        }
      } else {
        courseItems.value = courseItems.value.slice(0, 3);
      }
    });

    // 배너
    getBanerList({
      banerDivCdDcd: banerDivCdDcds.baner,
      banerExpsrLocCdDcd: banerExpsrLocCdDcds.main,
      pageNo: 1,
      pageSize: 3
    }, baners, () => {
      isBannerLoading.value = true;
      if (session.value.pmsnSn === 5) {
        getJuniorBaner(baners.value);
      }
    });

    const popups = ref([]);

    // 팝업
    getBanerList({
      banerDivCdDcd: banerDivCdDcds.popup,
      banerExpsrLocCdDcd: banerExpsrLocCdDcds.main,
      pageNo: 1,
      pageSize: 10
    }, popups);

    const controlledSwiper = ref(null);
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };

    const moveSlide = (isNext) => {
      if (controlledSwiper.value) {
        if (isNext) {
          controlledSwiper.value.slideNext();
        } else {
          controlledSwiper.value.slidePrev();
        }
      }
    }

    const slideChange = (swiper) => {
      isBeginning.value = swiper.isBeginning;
      isEnd.value = swiper.isEnd;
    }

    const moveToBoardDtl = (item) => {
      router.push({name:'BoardView',params:{'boardId':item.boardId, 'boardSecuKey':item.boardSecuKey}});
    }

    getHotTipList('hottip-my');

    // HRD 클라우드
    getHrdCloudList(hrdCloudFilter);

    getHrdCloudList(shortsFilter);

    // SSL
    getsslCardList(sslCardFilter);

    //  TODAY 연수
    getLearnHistoryList();

    // 최근배지
    getBadgeList();

    // 임시
    const goNote = (path) => {
      store.dispatch(`auth/${ACT_SSL_SSO_AUTH}`, path).then(res => {
        if (res) {
          openPopUp(res);
        }
      });
    }

    const moveLink = (banerUrl) => {
      if (!banerUrl || !isKbBank.value) {
        return false;
      } else {
        if (banerUrl === 'junior') {
          ssoKB(banerUrl);
        } else {
          goLink(banerUrl);
        }
      }
    }

    const moveTrain = (view, item) => {
      if (session.value.pmsnSn === 5) {
        showMessage('신입행원 연수기간에는 신청할 수 없습니다.');
      } else {
        if (view === 'trainMain') {
          router.push({path: '/apply/train'});
        } else if (view === 'trainView') {
          router.push({name: 'ApplyTrainView', params: {distCrseSn: item.crseDists[0].distCrseSn}});
        }

      }
    }

    return {
      session,
      bannerVideo,
      todayBanner,
      hrdCloudFilter,
      sslCardFilter,
      myStorage,
      mainList,
      sslList,
      items,
      shortsItems,
      sslitems,
      courseItems,
      isLoading, hrdLoading, sslLoading,
      hottipLoading,
      proceeding,
      scheduled,
      completed,
      badges,
      baners,
      popups,
      expirationPopup,
      isBadgeLoading,
      isBeginning,
      isEnd,
      isBannerLoading,
      isKbBank,

      ssoKB,
      goNote,

      moveSlide,
      slideChange,
      setControlledSwiper,

      getTodayDate,
      timestampToDateFormat,
      getThumbUrl,
      underConstruction,
      showMessage,
      moveLink,
      moveTrain,
      moveSSL,
      modules: [Navigation],
      hotTipMyPreviewList,
      moveToBoardDtl,

      subscribeCnt, //subscribeItems, sslSubscLoading,

    }
  }
};
</script>